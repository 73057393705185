@import 'styles/global';

.root {
  width: 100%;
  .container {
    margin: auto;
    max-width: 550px;
    text-align: center;

    .cover {
      width: 300px;
      border-radius: 50%;
    }
  }
}
