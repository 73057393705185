// global scss
// https://www.figma.com/file/ikGWt09ZdZknNA9SRpQOmO/UA-MVP-UI?node-id=211%3A1336&t=jCMp1uDPfdejBovQ-0
// NOTE: Please keep in sync with `src/common/params.ts`

/* Layout */
$sm: 600px; // phone portrait
$md: 900px; // phone landscape or tablet
$lg: 1200px; // laptop
$xl: 1536px; // desktop

$appbar-height: 95px;
$sidebar-width: 100px;
$user-sidebar-width: 320px;

$spacing: 8px;
$margin: $spacing * 2;

/* Colors */
$color-primary-0: #000000;
$color-primary-10: #0a2c65;
$color-primary-20: #0d46a1;
$color-primary-30: #1564c0;
$color-primary-40: #1976d2;
$color-primary-50: #1e87e5;
$color-primary-60: #2095f3;
$color-primary-70: #42a5f5;
$color-primary-80: #6ab8f7;
$color-primary-90: #90caf9;
$color-primary-95: #bbdefb;
$color-primary-99: #fafdff;
$color-primary-100: #ffffff;
$color-secondary-0: #000000;
$color-secondary-10: #19212b;
$color-secondary-20: #21497c;
$color-secondary-30: #376596;
$color-secondary-40: #3f75a8;
$color-secondary-50: #4986ba;
$color-secondary-60: #5393c6;
$color-secondary-70: #64a2cd;
$color-secondary-80: #7ab2d5;
$color-secondary-90: #9bc7e2;
$color-secondary-95: #c0dced;
$color-secondary-99: #fafdff;
$color-secondary-100: #ffffff;
$color-success-0: #000000;
$color-success-10: #006838;
$color-success-20: #008f4d;
$color-success-30: #00b266;
$color-success-40: #00c473;
$color-success-50: #00d882;
$color-success-60: #00ea8f;
$color-success-70: #00f1a1;
$color-success-80: #00f6b4;
$color-success-90: #82f9cb;
$color-success-95: #b9fbdf;
$color-success-99: #e2fef2;
$color-success-100: #ffffff;
$color-error-0: #000000;
$color-error-10: #410e0b;
$color-error-20: #601410;
$color-error-30: #8c1d18;
$color-error-40: #b3261e;
$color-error-50: #dc362e;
$color-error-60: #e46962;
$color-error-70: #ec928e;
$color-error-80: #f2b8b5;
$color-error-90: #f9dedc;
$color-error-95: #fceeee;
$color-error-99: #fffbf9;
$color-error-100: #ffffff;
$color-neutral-0: #000000;
$color-neutral-10: #0d1012;
$color-neutral-20: #1b2024;
$color-neutral-30: #3c4146;
$color-neutral-40: #5a6065;
$color-neutral-50: #6e7479;
$color-neutral-60: #979da2;
$color-neutral-70: #b6bcc2;
$color-neutral-80: #d9dfe5;
$color-neutral-90: #e7eef3;
$color-neutral-95: #eef5fb;
$color-neutral-99: #fafdff;
$color-neutral-100: #ffffff;
$color-neutral-variant-0: #000000;
$color-neutral-variant-10: #1b2631;
$color-neutral-variant-20: #263444;
$color-neutral-variant-30: #475c72;
$color-neutral-variant-40: #57708a;
$color-neutral-variant-50: #657e9b;
$color-neutral-variant-60: #7c91ab;
$color-neutral-variant-70: #92a5bd;
$color-neutral-variant-80: #afbfd3;
$color-neutral-variant-90: #dfe6ec;
$color-neutral-variant-95: #eff5fa;
$color-neutral-variant-99: #fafdff;
$color-neutral-variant-100: #ffffff;
$color-surface: #fafdff;
$color-surface-1: #e7f2fc;
$color-surface-2: #e2edfa;
$color-surface-3: #dbe9f8;
$color-surface-4: #d9e7f8;
$color-surface-5: #d6e5f7;
$color-disabled: rgba(0, 0, 0, 0.38);

/* Shadow */
$box-shadow-white-1: 0px 1px 8px rgba(62, 64, 66, 0.05); // white S
$box-shadow-white-2: 0px 4px 12px rgba(62, 64, 66, 0.09); // white M
$box-shadow-button-1: 0px 8px 16px rgba(55, 41, 201, 0.16); // button primary
$box-shadow-button-2: 0px 8px 16px rgba(151, 147, 199, 0.16); // button primary 95%
$box-shadow-button-3: 0px 8px 16px rgba(158, 163, 168, 0.16); // button white
$box-shadow-button-4: 0px 8px 16px rgba(62, 134, 145, 0.16); // button secondary

/* border */
$border: 1px solid $color-neutral-variant-50;
$border-disabled: 1px solid $color-disabled;

/* border radius */
$border-radius: 8px;

/* panel width */
$panel-width: 750px;
