@import 'styles/global.scss';

.root {
  height: 100%;
  width: 100%;

  gap: $spacing;
  display: flex;
  align-items: center;
  justify-content: center;
}
