@import 'styles/global.scss';

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    width: 100%;
    flex: 1;
    overflow: auto;
    transition: margin-left 0.2s ease;
  }
}
