@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .content {
    min-width: 500px;
    padding: 0 $spacing * 3;

    @media screen and (max-width: $sm) {
      min-width: 300px;
    }
  }
  .actions {
    padding: $spacing * 3;
    justify-content: center;
  }
}
