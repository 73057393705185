@import 'styles/global.scss';

$img-container-size: 660px;

.root {
  width: 100%;
  height: 100vh;

  .imgContainer {
    background-image: url(../../../public/images/auth/background.png);
    width: 50%;
    min-width: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: $md) {
      display: none;
    }

    .img {
      height: 100%;
    }
  }

  .block {
    padding: $spacing * 2;
    flex: 1 1;

    .container {
      margin: auto;
      text-align: center;

      @media screen and (max-width: $md) {
        margin: unset;
        margin-top: $spacing * 8;
      }

      .logo {
        height: 56px;
        margin-bottom: $spacing * 2;
      }

      .btn {
        border-radius: $spacing;
        height: 54px;
      }

      .emailBtn {
        height: 54px;
      }
    }
  }
}
