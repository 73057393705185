@import 'styles/global';

$chip-size: 48px;

.chip {
  color: $color-primary-40;
  background-color: $color-primary-90;
  border-radius: $chip-size * 0.5;
  border: 1px solid $color-primary-90;
  width: $chip-size;
  height: $chip-size;

  .icon {
    margin: auto;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
