@import 'styles/global.scss';

$img-container-size: 660px;

.root {
  width: 100%;
  height: 100vh;

  .imgContainer {
    width: 50%;
    min-width: $img-container-size;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a3065;

    @media screen and (max-width: $md) {
      display: none;
    }

    .img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 50%;
      padding: 15%;
    }
  }

  .block {
    padding: $spacing * 2;
    flex: 1 1;

    .container {
      margin: auto;
      text-align: center;

      @media screen and (max-width: $md) {
        margin: unset;
        margin-top: $spacing * 8;
      }

      .logo {
        height: 56px;
        margin-bottom: $spacing * 2;
      }

      .btn {
        border-radius: $spacing;
        height: 54px;
        margin-top: $spacing * 4.5;
      }
    }
  }
}

.dialog {
  padding: $spacing * 4;

  @media screen and (max-width: $sm) {
    padding: $spacing * 4 0;
  }

  .title {
    text-align: center;
  }
}
